export default {
  NEWS: {
    TITLE: "News e <b>articles</b>",
    BREADCRUMB: "News and articles",
    BREADCRUMB_ARTICLE: "Article",
    CATEGORY: "Categories",
    PERIOD: "Period",
    BUTTON: "Load more",
    ALL: "All",
    CALENDAR: {
      MONTHS: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      MONTHS_SHORT: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      WEEKDAYS: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      WEEKDAYS_SHORT: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      WEEKDAYS_MIN: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    },
  },
};
