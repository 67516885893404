import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_API_URL
        : process.env.VUE_APP_API_URL_DEV;

    Vue.axios.interceptors.request.use(
      (config) => {
        this.setHeader();

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.setHeader();
  },

  setHeader(token = "") {
    Vue.axios.defaults.headers.common["Authorization"] =
      token || this.getToken();

    // Vue.axios.defaults.headers.common['state'] = localStorage.getItem('state') || 'SP'
    Vue.axios.defaults.headers.common["language"] =
      localStorage.getItem("language") || "pt";
  },

  destroyToken() {
    localStorage.clear();
  },

  getToken() {
    const token = localStorage.getItem("token");

    if (token) return localStorage.getItem("token");

    return "";
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  get(resource, slug = "") {
    let url = slug ? `${resource}/${slug}` : `${resource}`;

    url = `${url}${
      url.includes("?") ? "&" : "?"
    }nocache=${new Date().getTime()}`;

    return Vue.axios.get(url);
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource);
  },
};

export default ApiService;
