export default {
  FOOTER: {
    LABEL_INSTITUTIONAL: "Institutional",
    WHO_WE_ARE: "Who we are",
    STORES: "Accredited network",
    SUPPORT: "Technical assistance",
    NEWS: "News",
    CONTACT_LINK: "Contact",
    WORK: "Work with use",
    PRODUCTS: "Products",
    SERVICES: "Services",
    BUY_BY_CATEGORY: "Shop by category",
    LABEL_INFO: "Account information and deliveries",
    CONTACT: "Contact",
    WEEK: "Mon-Fri",
    SATURDAY: "Sat",
    SOCIAL_MIDIA: "Follow us on social media",
    PAYMENT: "Payment options",
    COPYRIGHT: "All rights reserved.",
    POLICY: "Privacy Policy",
    TERMS: "Terms of use",
    MY_ACCOUNT: "My Account",
    EXCHANGE_RETURN: "Exchange and Return",
    TERMS_CONDITIONS: "Terms and Conditions"
  },
};
