export default {
  ABOUT: {
    HELLO: "Hi, from about",
    TITLE: "Who we are",
    BREADCRUMB: "Who we are",
    MISSION: "Mission",
    VISION: "Vision",
    VALUE: "Dedicated",
    AREA: "Area",
    OPENING: "Opening",
    PHONE: "Phone",
    ROUTE: "Trace route",
  },
};
