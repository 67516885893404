export default {
  HOME: {
    SLIDE_BUTTON: "See more",
    CATEGORIES_HEADER: "The best equipment on the market",
    CATEGORY_BUTTON: "Explore",
    PRODUCTS: "Products",
    IN: "In",
    HIGHLIGHT: "highlight",
  },
};
