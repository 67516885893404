export default {
  STORES: {
    TITLE: "Accredited <b>network</b>",
    BREADCRUMB: "Services",
    ACREDITED_NETWORK: "Accredited network",
    PLACEHOLDER: "Search the accredited network",
    STATE: "State",
    EMPTY: "We do not have stores in this state",
  },
};
