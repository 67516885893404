import GENERAL from "./general/index.js";
import HEADER from "./header/index.js";
import FOOTER from "./footer/index.js";
import HOME from "./home/index.js";
import ABOUT from "./about/index.js";
import NEWS from "./news/index.js";
import STORES from "./stores/index.js";
import SUPPORT from "./support/index.js";
import CONTACT from "./contact/index.js";

export const locale = {
  ...GENERAL,
  ...HEADER,
  ...FOOTER,
  ...HOME,
  ...ABOUT,
  ...NEWS,
  ...STORES,
  ...SUPPORT,
  ...CONTACT,
  HELLO: "Holla!",
};
