export default {
  CONTACT: {
    TITLE: "Entre em <b>contato</b>",
    BREADCRUMB: "Contato",
    BANNER: {
      LABEL: "Conheça a cozinha da Macom",
      BUTTON: "Saiba mais",
    },
    WORK: {
      TITLE: "Trabalhe conosco",
      TITLE_HTML: "Trabalhe <b>conosco</b>",
      SUBTITLE: "Preencha um formulário, logo entraremos em contato.",
      BUTTON: "Trabalhe conosco",
    },
  },
};
