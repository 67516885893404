export default {
  FOOTER: {
    LABEL_INSTITUTIONAL: "Institucional",
    WHO_WE_ARE: "Quienes somos",
    STORES: "Red acreditada",
    SUPPORT: "Asistencia técnica",
    NEWS: "Noticias",
    CONTACT_LINK: "Contacto",
    WORK: "Trabaja con nosotros",
    PRODUCTS: "Productos",
    SERVICES: "Servicios",
    BUY_BY_CATEGORY: "Compra por categoría",
    LABEL_INFO: "Información de cuenta y entregas",
    CONTACT: "Entre en contacto",
    WEEK: "Lun-vie",
    SATURDAY: "Sáb",
    SOCIAL_MIDIA: "Síguenos en las redes sociales",
    PAYMENT: "Medios de pago",
    COPYRIGHT: "Todos los derechos reservados.",
    POLICY: "Política de privacidad",
    TERMS: "Terminos de uso",
    MY_ACCOUNT: "Mi cuenta",
    EXCHANGE_RETURN: "Cambio y devolución",
    TERMS_CONDITIONS: "Términos y condiciones"
  },
};
