export default {
  GENERAL: {
    COOKIES: {
      LABEL: "We use cookies to ensure the best experience on our website.",
      LINK: "Privacy Policy.",
      BTN_OK: "Accept",
      BTN_CANCEL: "I do not accept",
    },
    CONTACT: {
      ADDRESS: "Address",
      BUTTON: "Open in Google Maps",
      SCHEDULE: "From Mon. to Fri. from {opening} to {closing}",
      EMAIL_LABEL: "If you prefer, please contact us by email:",
      SOCIAL_MIDIA: "Follow us on social media",
    },
    BACK: "Back",
    ALSO_READ: "Read too",
    READ_MORE: "Read more",
    OTHERS_CATEGORIES: "Other categories",
    PRODUCTS: "Products",
    PRODUCT_LINE: "Product Line",
    CART: "Cart",
  },
};
