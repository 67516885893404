import Vue from "vue";
import Router from "vue-router";
// import store from "../store";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: () => import("../layout/index.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/home/index.vue"),
        meta: {
          showNavApplication: true,
        },
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/about/index.vue"),
        meta: {
          showNavApplication: true,
          pageTitle: "Quem somos",
          breadcrumbs: ["Home", "ABOUT.BREADCRUMB"],
        },
      },
      {
        path: "/stores",
        name: "stores",
        component: () => import("../views/stores/index.vue"),
        meta: {
          showNavApplication: true,
          pageTitle: "Serviços",
          breadcrumbs: [
            "Home",
            "STORES.BREADCRUMB",
            "STORES.ACREDITED_NETWORK",
          ],
        },
      },
      {
        path: "/support",
        name: "support",
        component: () => import("../views/support/index.vue"),
        meta: {
          showNavApplication: true,
          pageTitle: "Serviços",
          breadcrumbs: ["Home", "STORES.BREADCRUMB", "SUPPORT.BREADCRUMB"],
        },
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/news/index.vue"),
        meta: {
          showNavApplication: true,
          pageTitle: "Notícias",
          breadcrumbs: ["Home", "NEWS.BREADCRUMB"],
        },
      },
      {
        path: "/news/:id",
        name: "news-details",
        component: () => import("../views/news-details/index.vue"),
        meta: {
          showNavApplication: true,
          pageTitle: "Notícias",
          breadcrumbs: ["Home", "NEWS.BREADCRUMB", "NEWS.BREADCRUMB_ARTICLE"],
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("../views/contact/index.vue"),
        meta: {
          showNavApplication: true,
          pageTitle: "Contato",
          breadcrumbs: ["Home", "CONTACT.BREADCRUMB"],
        },
      },
      {
        path: "/work",
        name: "work",
        component: () => import("../views/work/index.vue"),
        meta: {
          showNavApplication: true,
          pageTitle: "Contato",
          breadcrumbs: ["Home", "CONTACT.BREADCRUMB", "CONTACT.WORK.TITLE"],
        },
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/category/index.vue"),
        meta: {
          showNavApplication: true,
          pageTitle: "Categoria",
          breadcrumbs: ["Home", "GENERAL.PRODUCTS"],
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
];

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// router.beforeEach(async (to, from, next) => {
//   if (store.getters["auth/getIsAuthenticaded"]) {
//     await store.dispatch("auth/verifyAuth");
//   }
//   next();
// });

export default router;
