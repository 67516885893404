export default {
  HEADER: {
    SEARCH_PLACEHOLDER: "Buscar productos por palabra clave",
    HOME: "Home",
    WHO_WE_ARE: "Quienes somos",
    PRODUCTS: "Productos",
    SERVICES: "Servicios",
    STORE: "Red acreditada",
    SUPPORT: "Asistencia técnica",
    NEWS: "Noticias",
    CONTACT: "Contacto",
    ONLINE: "Tienda online",
    CATEGORIES: "Categorías",
  },
};
