export default {
  GENERAL: {
    COOKIES: {
      LABEL:
        "Utilizamos cookies para garantizar la mejor experiencia en nuestro sitio web.",
      LINK: "Política de privacidad.",
      BTN_OK: "Acepto",
      BTN_CANCEL: "No acepto",
    },
    CONTACT: {
      ADDRESS: "Dirección",
      BUTTON: "Abrir en Google Maps",
      SCHEDULE: "Lunes a viernes da {opening} a {closing}",
      EMAIL_LABEL:
        "Si lo prefieres, ponte en contacto con nosotros por correo electrónico:",
      SOCIAL_MIDIA: "Síguenos en las redes sociales",
    },
    BACK: "Volter",
    ALSO_READ: "Leer también",
    READ_MORE: "Lea mas",
    OTHERS_CATEGORIES: "Otras categorias",
    PRODUCTS: "Productos",
    PRODUCT_LINE: "Línea de Productos",
    CART: "Carro",
  },
};
