export default {
  NEWS: {
    TITLE: "Noticias y <b>artículos</b>",
    BREADCRUMB: "Noticias y artículos",
    BREADCRUMB_ARTICLE: "Artículo",
    CATEGORY: "Categorías",
    PERIOD: "Período",
    BUTTON: "Carga más",
    ALL: "Todo",
    CALENDAR: {
      MONTHS: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      MONTHS_SHORT: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      WEEKDAYS: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      WEEKDAYS_SHORT: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      WEEKDAYS_MIN: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    },
  },
};
