import Vue from "vue";
import Vuex from "vuex";

import moduleAuth from "./auth";
import moduleCart from "./cart";
import moduleSearch from "./search";
import moduleCategory from "./category";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: moduleAuth,
    cart: moduleCart,
    search: moduleSearch,
    category: moduleCategory,
  },
});
