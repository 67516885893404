export default {
  CONTACT: {
    TITLE: "<b>Contact</b>",
    BREADCRUMB: "Contact",
    BANNER: {
      LABEL: "Discover Macom's kitchen",
      BUTTON: "Know more",
    },
    WORK: {
      TITLE: "Work with us",
      TITLE_HTML: "Work <b>with us</b>",
      SUBTITLE: "Fill out a form, we will contact you shortly.",
      BUTTON: "Work with us",
    },
  },
};
