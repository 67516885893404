export default {
  ABOUT: {
    HELLO: "Holla, de sobre",
    TITLE: "Quienes somos",
    BREADCRUMB: "Quienes somos",
    MISSION: "Misión",
    VISION: "Visión",
    VALUE: "Dedicado",
    AREA: "Área",
    OPENING: "Apertura",
    PHONE: "Teléfono",
    ROUTE: "Ruta de seguimiento",
  },
};
