export default {
  HOME: {
    SLIDE_BUTTON: "Veja mais",
    CATEGORIES_HEADER: "Os melhores equipamentos do mercado",
    CATEGORY_BUTTON: "Explorar",
    PRODUCTS: "Produtos",
    IN: "Em",
    HIGHLIGHT: "destaque",
  },
};
