export default {
  NEWS: {
    TITLE: "Notícias e <b>artigos</b>",
    BREADCRUMB: "Notícias e artigos",
    BREADCRUMB_ARTICLE: "Artigo",
    CATEGORY: "Categorias",
    PERIOD: "Período",
    BUTTON: "Carregar mais",
    ALL: "Todas",
    CALENDAR: {
      MONTHS: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      MONTHS_SHORT: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      WEEKDAYS: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      WEEKDAYS_SHORT: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sat"],
      WEEKDAYS_MIN: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
    },
  },
};
