export default {
  FOOTER: {
    LABEL_INSTITUTIONAL: "Institucional",
    WHO_WE_ARE: "Quem somos",
    STORES: "Rede credenciada",
    SUPPORT: "Assistência Técnica",
    NEWS: "Notícias",
    CONTACT_LINK: "Contato",
    WORK: "Trabalhe conosco",
    PRODUCTS: "Produtos",
    SERVICES: "Serviços",
    BUY_BY_CATEGORY: "Compre por categoria",
    LABEL_INFO: "Informações de conta e entregas",
    CONTACT: "Entre em contato",
    WEEK: "Seg-sex",
    SATURDAY: "Sáb",
    SOCIAL_MIDIA: "Siga-nos nas redes sociais",
    PAYMENT: "Meios de pagamento",
    COPYRIGHT: "Todos os direitos reservados.",
    POLICY: "Política de Privacidade",
    TERMS: "Termos de uso",
    MY_ACCOUNT: "Minha conta",
    EXCHANGE_RETURN: "Troca e devolução",
    TERMS_CONDITIONS: "Termos e condições"
  },
};
