export default {
  CONTACT: {
    TITLE: "Entre en <b>contacto</b>",
    BREADCRUMB: "Contacto",
    BANNER: {
      LABEL: "Descubre la cocina de Macom",
      BUTTON: "Sepa mas",
    },
    WORK: {
      TITLE: "Trabaja con nosotros",
      TITLE_HTML: "Trabaja con <b>nosotros</b>",
      SUBTITLE:
        "Llene un formulario, nos comunicaremos con usted a la brevedad.",
      BUTTON: "Trabaja con nosotros",
    },
  },
};
