export default {
  GENERAL: {
    COOKIES: {
      LABEL:
        "Utilizamos cookies para garantir a melhor experiência em nosso site.",
      LINK: "Política de privacidade.",
      BTN_OK: "Aceito",
      BTN_CANCEL: "Não aceito",
    },
    CONTACT: {
      ADDRESS: "Endereço",
      BUTTON: "Abrir no Google Maps",
      SCHEDULE: "De seg. à sex. das {opening} às {closing}",
      EMAIL_LABEL: "Se preferir entre em contato por e-mail:",
      SOCIAL_MIDIA: "Siga-nos nas redes sociais",
    },
    BACK: "Voltar",
    ALSO_READ: "Leia tambem",
    READ_MORE: "Leia mais",
    OTHERS_CATEGORIES: "Outras categorias",
    PRODUCTS: "Produtos",
    PRODUCT_LINE: "Linha de Produtos",
    CART: "Carrinho",
  },
};
