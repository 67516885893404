import Vue from "vue";
import VueI18n from "vue-i18n";

import { locale as en } from "@/core/config/i18n/en/index.js";
import { locale as pt } from "@/core/config/i18n/pt/index.js";
import { locale as es } from "@/core/config/i18n/es/index.js";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, pt, es };

const lang = localStorage.getItem("language") || "pt";

const i18n = new VueI18n({
  locale: lang,
  messages,
});

export default i18n;
