export default {
  STORES: {
    TITLE: "Rede <b>credenciada</b>",
    BREADCRUMB: "Serviços",
    ACREDITED_NETWORK: "Rede credenciada",
    PLACEHOLDER: "Busque na rede credenciada",
    STATE: "Estado",
    EMPTY: "Não temos lojas nesse estado",
  },
};
