export default {
  STORES: {
    TITLE: "Red <b>acreditada</b>",
    BREADCRUMB: "Servicios",
    ACREDITED_NETWORK: "Red acreditada",
    PLACEHOLDER: "Buscar en la red acreditada",
    STATE: "Estado",
    EMPTY: "No tenemos tiendas en este estado.",
  },
};
