export default {
  HOME: {
    SLIDE_BUTTON: "Vea mas",
    CATEGORIES_HEADER: "Los mejores equipos del mercado.",
    CATEGORY_BUTTON: "Explorar",
    PRODUCTS: "Productos",
    IN: "En",
    HIGHLIGHT: "énfasis",
  },
};
