export default {
  HEADER: {
    SEARCH_PLACEHOLDER: "Search products by keyword",
    HOME: "Home",
    WHO_WE_ARE: "Who we are",
    PRODUCTS: "Products",
    SERVICES: "Services",
    STORE: "Accredited network",
    SUPPORT: "Technical assistance",
    NEWS: "News",
    CONTACT: "Contact",
    ONLINE: "Online store",
    CATEGORIES: "Categories",
  },
};
