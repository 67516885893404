export default {
  ABOUT: {
    HELLO: "Oi, de sobre",
    BREADCRUMB: "Quem somos",
    TITLE: "Quem Somos",
    MISSION: "Missão",
    VISION: "Visão",
    VALUE: "Dedicados",
    AREA: "Área",
    OPENING: "Inauguração",
    PHONE: "Telefone",
    ROUTE: "Traçar rota",
  },
};
