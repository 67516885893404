export default {
  HEADER: {
    SEARCH_PLACEHOLDER: "Buscar produtos por palavra-chave",
    HOME: "Home",
    WHO_WE_ARE: "Quem somos",
    PRODUCTS: "Produtos",
    SERVICES: "Serviços",
    STORE: "Rede Credenciada",
    SUPPORT: "Assistência Técnica",
    NEWS: "Notícias",
    CONTACT: "Contato",
    ONLINE: "Loja online",
    CATEGORIES: "Categorias",
  },
};
